import Subpage from "../subpage/Subpage";
import modules from "./styles/NotFound.module.css";

const NotFound = () => {
  return (
    <Subpage titleText="Not Found">
      <div className={modules.Container}>
        You are on a page that doesn't exist.{" "}
      </div>
    </Subpage>
  );
};

export default NotFound;
