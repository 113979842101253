import {
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  useMediaQuery,
} from "@mui/material";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import AgendaCardMCCF from "../../atoms/AgendaCardMCCF";
import ContactUsForm from "../../atoms/ContactUsForm";
import VisitUsCard from "../../atoms/VisitUsCard";
import { handleScrollTo } from "../../utils/UtilFunctions";
import Subpage from "../subpage/Subpage";
import meeting_info from "../../resources/mccf_meeting.json";
import modules from "./styles/MCCF.module.css";

const mccfEventImagePrefix =
  "https://storage.googleapis.com/acafamily/images/mccf_events";

const photos = [
  {
    img: `${mccfEventImagePrefix}/welcome.JPG`,
    title: "福音团契欢迎你！",
  },
  {
    img: `${mccfEventImagePrefix}/dinner.jpg`,
    title: "每周五晚上，我们提供可口的中餐",
  },
  {
    img: `${mccfEventImagePrefix}/new_student_welcome.jpg`,
    title: "迎新见证分享聚会",
  },
  {
    img: `${mccfEventImagePrefix}/picnic.jpg`,
    title: "外出野餐与露营",
  },
  {
    img: `${mccfEventImagePrefix}/family_meeting.jpg`,
    title: "在弟兄姊妹家中聚会",
  },
  {
    img: `${mccfEventImagePrefix}/singing.JPG`,
    title: "唱诗歌",
  },
  {
    img: `${mccfEventImagePrefix}/bible_study.jpg`,
    title: "查考圣经",
  },
  {
    img: `${mccfEventImagePrefix}/children_service.jpg`,
    title: "儿童活动",
  },
];

const MCCF = () => {
  const contactFormRef = useRef(null);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  return (
    <Subpage
      titleText="MCCF福音团契"
      backgroundImageUrl="https://storage.googleapis.com/acafamily/images/mccf_cover.JPG"
    >
      <div className={modules.Container}>
        <Helmet>
          <title>MCCF福音团契</title>
          <meta name="description" content="MCCF福音团契 - 您在远方的家" />
          <meta name="title" content="MCCF福音团契 - 您在远方的家" />
        </Helmet>
        <div className={modules.BodyContainer}>
          <div className={modules.LeftContainer}>
            <p style={{ fontWeight: "bold" }}>亲爱的学生朋友:</p>
            <p>欢迎您来到奥斯汀留学生的海外之家 - 福音团契!</p>
            <p>
              我们来自五湖四海，在德州大学读书，平时课业繁忙，彼此相识相交的机会不多。背井离乡的你，身处文化差异很大的环境，说着另一种语言，想必偶尔会感到孤单疲惫、迷茫无助，不知该找谁倾诉。
              但您可能没想到，在校园西边的一栋高楼里，每个礼拜五晚上都有一个很特别、很温馨的聚会，在等待着所有愿意追求人生真谛、和坚固友谊的学生朋友们来参加!
            </p>
            <p>
              我们这群早些年来此读书的海外游子，从小受无神论教育，刚开始接触基督徒的聚集也颇感陌生。但慢慢地，我们在这个福音团契里认识了上帝--这位独一的真神，借着悔改和相信主耶稣，成为了基督徒。
              从此，不仅人生有了目标和期盼，生活能常享喜乐和安宁，心灵也找到了可安歇的家园！
            </p>
            <p>
              我们衷心盼望能把这位最知心的朋友和救主耶稣介绍给您。所以，我们诚挚地邀请您来参加礼拜五福音团契的聚会。
              我们备有晚餐，之后会一起唱诗与小组研读《圣经》。我们平时定期组织专题讲座、见证分享，还有一些娱乐活动。愿大家在此一同蒙恩。
              求神祝福您!
            </p>{" "}
            <p style={{ fontWeight: "bold" }}>福音团契的弟兄姐妹敬上</p>
          </div>
          <div className={modules.RightContainer}>
            <AgendaCardMCCF
              meetingFormat={meeting_info.meeting_format}
              bibleChapter={meeting_info.bible_chapter}
              date={meeting_info.date}
            />
            <Box mt="16px" />
            <VisitUsCard
              address="2204 San Antonio St., Austin, TX 78705"
              mapUrl="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6890.589608254891!2d-97.743156!3d30.285668!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb7848abe97445906!2sLonghorn%20BSM!5e0!3m2!1sen!2sus!4v1646519492366!5m2!1sen!2sus"
              mapSearchUrl="https://www.google.com/maps?ll=30.285668,-97.743156&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=13223846957052877062"
              contactUsAction={() => handleScrollTo(contactFormRef)}
            />
          </div>
        </div>
        <Box mt="64px" />
        <div
          className={modules.BodyContainer}
          style={{ flexDirection: "column" }}
        >
          <div className={modules.SectionHeader}>团契活动</div>
          <div>
            <p>
              我们盼望福音团契能够成为您在远方的家！以下是我们在学年当中的一些活动：
            </p>
            <ImageList
              sx={{
                width: "100%",
                height: "100%",
              }}
              gap={isDesktop ? 36 : 24}
              cols={isDesktop ? 2 : 1}
            >
              {photos.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    srcSet={item.img}
                    src={item.img}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar title={item.title} position="bottom" />
                </ImageListItem>
              ))}
            </ImageList>
          </div>
        </div>
        <Box mt="64px" ref={contactFormRef} />
        <ContactUsForm src="MCCF" />
        <Box mt="64px" />
        <div className={modules.Verse}>
          <p>
            神爱世人，甚至将他的独生子赐给他们，叫一切信他的，不至灭亡，反得永生。
          </p>
          <p>——约翰福音 3:16</p>
        </div>
      </div>
    </Subpage>
  );
};

export default MCCF;
