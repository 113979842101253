import Subpage from "../subpage/Subpage";

import modules from "./styles/Resources.module.css";

const Zoom = () => {
  return (
    <Subpage
      titleText="Zoom"
      backgroundImageUrl="https://storage.googleapis.com/acafamily/images/fm_cover.JPG"
    >
      <div className={modules.Container}>
        <a
          href="https://us02web.zoom.us/j/83485580606?pwd=S1JGcitmSC9ieis0dThCUkxyajhJZz09"
          target="_blank"
        >
          The Lord's Table Meeting
        </a>
        <a
          href="https://us02web.zoom.us/j/102042969?pwd=NzYxN1dVZU5zeW1MWHV2bGpRYkNwUT09"
          target="_blank"
        >
          Wednesday Prayer Meeting
        </a>
        <a href="https://zoom.us/j/369228309" target="_blank">
          Friday Campus Meeting (MCCF)
        </a>
      </div>
    </Subpage>
  );
};

export default Zoom;
