import { Box } from "@mui/material";
import { useRef, useState } from "react";
import ContactUsForm from "../../atoms/ContactUsForm";
import AnnouncementCard from "../../atoms/AnnouncementCard";
import UsefulLinks from "../../atoms/UsefulLinks";
import VisitUsCard from "../../atoms/VisitUsCard";
import WelcomeBadge from "../../atoms/WelcomeBadge";
import { handleScrollTo } from "../../utils/UtilFunctions";
import MeetingScheduleTabs from "./MeetingScheduleTabs";

import modules from "./styles/Home.module.css";

const Home = () => {
  const leftContainerRef = useRef(null);
  const contactFormRef = useRef(null);
  const [windowInnerWidth, setWindowInnerWidth] = useState(
    Math.min(window.innerWidth, 1280) - 64 - 300
  );

  return (
    <div className={modules.Container}>
      <WelcomeBadge />
      <Box mt="32px" />

      <div className={modules.BodyContainer}>
        <div className={modules.LeftContainer} ref={leftContainerRef}>
          {/* <div className={modules.SectionTitleText}>Announcements</div>
          <Box mt="8px" />
          <div className={modules.SectionTitleText}>通知</div>
          <Box mt="18px" />
          <AnnouncementCard
            description="Due to Summer Conference, there will be no meeting held at ACA for the Sunday service tomorrow, 6/9.  For those of you who are not attending the conference in-person, you can consider to commute to UIW to attend the Sunday meeting.  The Lord's Table meeting will start at 9:15am. The message meeting will follow and start at 10:30am and will also be live streamed. For directions to the UIW campus or livestreaming, please refer to "
            link_url="https://hcafamily.org/"
            link_text="the HCA website"
          />
          {/* <AnnouncementCard description="ACA has resumed Sunday in-person meeting. However, we would request you stay at home and attend the online service if you or your household members had the symptoms of COVID-19 (fever, cough, headache, runny nose, loss of sense of smell/taste, sore throat, and/or diarrhea), or have close contact with people who have got COVID-19 in the past 7 days. We will also continue using YouTube to live stream the Sunday meeting at 11:00 A.M CT." />
          <Box mt="18px" /> */}

          <div className={modules.SectionTitleText}>
            Weekly Meeting Schedule
          </div>
          <Box mt="8px" />
          <div className={modules.SectionTitleText}>每週聚會時間</div>
          <Box mt="18px" />
          <MeetingScheduleTabs />
          <Box mt="18px" />
        </div>

        <div className={modules.RightContainer}>
          <VisitUsCard contactUsAction={() => handleScrollTo(contactFormRef)} />
          <Box mt="40px" />
          <UsefulLinks />
        </div>
      </div>
      <Box mt="64px" ref={contactFormRef} />
      <ContactUsForm />
    </div>
  );
};

export default Home;
