import { Box, Paper } from "@mui/material";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import YouTubeIcon from "@mui/icons-material/YouTube";
import modules from "./styles/Messages.module.css";
import { navigateToNewPage } from "../../utils/UtilFunctions";

interface MessageInfoProps {
  title: string;
  date: string;
  speaker: string;
  location: string;
  audioUrl?: string | null;
  videoUrl?: string | null;
}

const MessageInfo: React.FC<MessageInfoProps> = ({
  title,
  date,
  speaker,
  location,
  audioUrl = null,
  videoUrl = null,
}) => {
  return (
    <Paper classes={{ root: modules.InfoContainer }} elevation={3}>
      <span className={modules.HeaderTitleSpan}>{title}</span>
      <span className={modules.HeaderInfoSpan}>{date}</span>
      <span className={modules.HeaderInfoSpan}>{speaker}</span>
      <span className={modules.HeaderInfoSpan}>{location}</span>
      <span className={modules.HeaderInfoSpan}>
        {audioUrl && (
          <PodcastsIcon
            fontSize="large"
            style={{ color: "blue", height: "40px", cursor: "pointer" }}
            onClick={() => navigateToNewPage(audioUrl)}
          />
        )}
        {audioUrl && videoUrl && <Box ml="10px" />}
        {videoUrl && (
          <YouTubeIcon
            fontSize="large"
            style={{ color: "red", height: "40px", cursor: "pointer" }}
            onClick={() => navigateToNewPage(videoUrl)}
          />
        )}
      </span>
    </Paper>
  );
};

export default MessageInfo;
