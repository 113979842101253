import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NotFound from "../views/404/NotFound";
import Home from "../views/home/Home";
import Messages from "../views/messages/Messages";
import MCCF from "../views/ministries/MCCF";
import Olivet from "../views/ministries/Olivet";
import FT2022 from "../views/resources/FT2022";
import FamilyGathering from "../views/family-gathering/FamilyGathering";
import Resources from "../views/resources/Resources";
import Zoom from "../views/resources/Zoom";
import ACA from "../views/resources/ACA";
import MenuAppBar from "./MenuAppBar";
import MenuAppFooter from "./MenuAppFooter";

import modules from "./styles/Viewport.module.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EB5757",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const Viewport: React.FC = () => {
  // Make components lazy load if have performance issue
  return (
    <ThemeProvider theme={theme}>
      <div className={modules.Root}>
        <MenuAppBar />
        <div className={modules.Container}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mccf" element={<MCCF />} />
              <Route path="/olivet" element={<Olivet />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/family-gathering" element={<FamilyGathering />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/zoom" element={<Zoom />} />
              <Route path="/aca" element={<ACA />} />
              <Route path="/ft2022" element={<FT2022 />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </div>
        <MenuAppFooter />
      </div>
    </ThemeProvider>
  );
};

export default Viewport;
