import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MeetingCard from "../../atoms/MeetingCard";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingY: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MeetingScheduleTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Sundays 主日" {...a11yProps(0)} disableRipple />
          <Tab label="Wednesdays 週三" {...a11yProps(2)} disableRipple />
          <Tab label="Fridays 週五" {...a11yProps(1)} disableRipple />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MeetingCard
          mtgEnName="Lord's Table Meeting"
          mtgCnName="擘餅聚會"
          time="10:00 - 10:45am CT"
          location="In-person at 4511 Menchaca & Zoom"
        />
        <Box mt="24px" />
        <MeetingCard
          mtgEnName="Message / Children Sunday School"
          mtgCnName="信息聚會 / 兒童主日學"
          time="11:00am - 12:30pm CT"
          location="In-person at 4511 Menchaca & YouTube livestream"
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MeetingCard
          mtgEnName="Prayer Meeting"
          mtgCnName="禱告聚會"
          time="7:30 - 9:30pm CT"
          location="In-person at 4511 Menchaca & Zoom"
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MeetingCard
          mtgEnName="Chinese/English Bible Study"
          mtgCnName="交通中心查經聚會"
          time="7:30 - 9:30pm CT"
          location="In-person at 4511 Menchaca & Zoom"
        />
        <Box mt="24px" />
        {/* TODO: move Olivet card to later once time is updated */}
        <MeetingCard
          mtgEnName="Olivet Bible Fellowship"
          time="5:00 - 6:30pm CT"
          location="In-person at Longhorn BSM & Zoom"
        />
        <Box mt="24px" />
        <MeetingCard
          mtgEnName="Campus Bible Study"
          mtgCnName="校園查經聚會"
          time="7:30 - 9:30pm CT"
          location="In-person at Longhorn BSM & Zoom"
        />
      </TabPanel>
    </Box>
  );
}
