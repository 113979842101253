import { Box } from "@mui/material";
import { useRef } from "react";
import AgendaCard from "../../atoms/AgendaCard";
import ContactUsForm from "../../atoms/ContactUsForm";
import VisitUsCard from "../../atoms/VisitUsCard";
import { handleScrollTo } from "../../utils/UtilFunctions";
import Subpage from "../subpage/Subpage";
import meeting_info from "../../resources/olivet_meeting.json";

import modules from "./styles/MCCF.module.css";

const Olivet = () => {
  const contactFormRef = useRef(null);
  return (
    <Subpage
      titleText="Olivet Bible Fellowship"
      backgroundImageUrl="https://storage.googleapis.com/acafamily/images/mccf_cover_2.JPG"
    >
      <div className={modules.Container}>
        <div className={modules.BodyContainer}>
          <div className={modules.LeftContainer}>
            <p style={{ fontWeight: "bold" }}>
              Welcome to Olivet Bible Fellowship!
            </p>
            <p>
              Olivet is the name of the mountain in front of Jerusalem on the
              east. It was the place where the Lord Jesus spent most of His time
              outside of the Temple while in Jerusalem (e.g. the village of
              Bethany, the Garden of Gethsemane). It was here that, after
              resurrected, He ascended into heaven, and one day, it is here
              again that His feet will touch the earth when He returns
              (Zechariah 14:4).
            </p>
            <p>
              The Lord Jesus is the living Bible, the Word of Life from the
              beginning Who took on flesh, so that now in Him can we not only
              hear and see God, but also have Fellowship with the Lord and with
              one another (1 John 1:1-3). This is the reason we gather weekly at
              Olivet Bible Fellowship to study the Bible and fellowship together
              to know and love the Lord Jesus more and more in earnest
              anticipation of His return.
            </p>
          </div>
          <div className={modules.RightContainer}>
            <AgendaCard
              meetingFormat={meeting_info.meeting_format}
              bibleChapter={meeting_info.bible_chapter}
              date={meeting_info.date}
            />
            <Box mt="16px" />
            <VisitUsCard
              address="2204 San Antonio St., Austin, TX 78705"
              mapUrl="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6890.589608254891!2d-97.743156!3d30.285668!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb7848abe97445906!2sLonghorn%20BSM!5e0!3m2!1sen!2sus!4v1646519492366!5m2!1sen!2sus"
              mapSearchUrl="https://www.google.com/maps?ll=30.285668,-97.743156&z=14&t=m&hl=en&gl=US&mapclient=embed&cid=13223846957052877062"
              contactUsAction={() => handleScrollTo(contactFormRef)}
            />
          </div>
        </div>
        <Box mt="64px" ref={contactFormRef} />
        <ContactUsForm src="Olivet" />
      </div>
    </Subpage>
  );
};

export default Olivet;
