import Subpage from "../subpage/Subpage";

import modules from "./styles/Resources.module.css";

const ACA = () => {
  return (
    <Subpage
      titleText="ACA"
      backgroundImageUrl="https://storage.googleapis.com/acafamily/images/fm_cover.JPG"
    >
      <div className={modules.Container}>
        <a
          href="https://us02web.zoom.us/j/84692234638?pwd=T0FFNmt4TDlUVVk3MlVOeExRbEJPUT09"
          target="_blank"
        >
          Zoom Link for Sunday Speaker, Song Leader, and Translator
        </a>
      </div>
    </Subpage>
  );
};

export default ACA;
