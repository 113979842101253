import { Box, Paper } from "@mui/material";

import modules from "./styles/AgendaCard.module.css";

interface AgendaCardProps {
  meetingFormat: string;
  bibleChapter: string;
  date: string;
}

const AgendaCard: React.FC<AgendaCardProps> = ({
  meetingFormat,
  bibleChapter,
  date,
}) => {
  return (
    <Paper classes={{ root: modules.Container }} elevation={3}>
      <div className={modules.TitleText}>Meeting Summary</div>
      <Box mt="16px" />
      <div className={modules.BodyText}>
        <p style={{ fontWeight: "bold" }}>Meeting format:</p>
        <p>{meetingFormat}</p>
      </div>
      <div className={modules.BodyText}>
        <p style={{ fontWeight: "bold" }}>Bible chapter:</p>
        <p>{bibleChapter}</p>
      </div>
      <div className={modules.BodyText}>
        <p style={{ fontWeight: "bold" }}>Time:</p>
        <p>{date}</p>
        <ul>
          <li>Dinner: 6:30 - 7:20pm</li>
          <li>Bible study: 7:30 - 9:00pm</li>
        </ul>
      </div>
    </Paper>
  );
};

export default AgendaCard;
