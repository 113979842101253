import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { navigateToNewPage } from "../utils/UtilFunctions";
import BaseButton from "./BaseButton";

import modules from "./styles/ContactUsForm.module.css";

interface ContactUsFormProps {
  src?: string;
}

const sendEmail = (
  src: string,
  name: string,
  email: string,
  phone: string,
  msg: string
) => {
  navigateToNewPage(
    `mailto:aca.family.austin@gmail.com?subject=${
      `[${src}] ` + "Inquiry from " + name
    }&body=${
      msg + "%0D%0DYou can reach me at email: " + email + " or phone: " + phone
    }`
  );
};

const ContactUsForm: React.FC<ContactUsFormProps> = ({ src = "ACA" }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");

  return (
    <div className={modules.Container}>
      <div className={modules.TitleText}>Contact Us</div>
      <div className={modules.TitleText}>聯系我們</div>
      <Box mt="28px" />
      <TextField
        id="outlined-required"
        label="Name 姓名"
        variant="outlined"
        required
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        id="outlined-required"
        label="E-mail Address 電子郵件"
        variant="outlined"
        required
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        id="outlined"
        label="Phone Number 電話號碼"
        variant="outlined"
        fullWidth
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        id="outlined"
        label="How can we help you? 請在此填入您的問題"
        multiline
        rows={4}
        fullWidth
        value={msg}
        onChange={(e) => setMsg(e.target.value)}
        InputLabelProps={{ shrink: true }}
      />
      <BaseButton
        dark
        text={"Submit 提交"}
        extraCssClass={modules.ButtonSize}
        action={(e) => sendEmail(src, name, email, phone, msg)}
      />
    </div>
  );
};

export default ContactUsForm;
