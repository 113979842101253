import { Box, Paper } from "@mui/material";
import { navigateToNewPage } from "../utils/UtilFunctions";
import BaseButton from "./BaseButton";

import modules from "./styles/VisitUsCard.module.css";

interface VisitUsCardProps {
  address?: string;
  mapUrl?: string;
  mapSearchUrl?: string;
  contactUsAction: () => void;
}

const VisitUsCard: React.FC<VisitUsCardProps> = ({
  address = "4511 Menchaca Rd. Austin, TX 78745",
  mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3447.3498243512267!2d-97.7918715839404!3d30.227099116873763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865b4b4a07e47f61%3A0x8223fad4912fc205!2sAustin%20Christian%20Assembly!5e0!3m2!1sen!2sus!4v1646513105777!5m2!1sen!2sus",
  mapSearchUrl = "https://www.google.com/maps?ll=30.227095,-97.789683&z=15&t=m&hl=en&gl=US&mapclient=embed&cid=9377614639991341573",
  contactUsAction,
}) => {
  const mapFrame = `<iframe src='${mapUrl}' style="
    border: none;
    border-radius: 10px;
    height: 100%;
    width: 100%;
"/>`;

  return (
    <Paper classes={{ root: modules.Container }} elevation={3}>
      <div className={modules.TitleText}>VISIT US IN PERSON 地址</div>
      <Box mt="20px" />
      <div className={modules.AddrText}>{address}</div>
      <Box mt="20px" />
      <div
        className={modules.MapContainer}
        dangerouslySetInnerHTML={{
          __html: mapFrame,
        }}
      />
      <Box mt="20px" />
      <BaseButton
        extraCssClass={modules.ButtonSize}
        text="Get Directions 導航"
        dark
        action={() => navigateToNewPage(mapSearchUrl)}
      />
      <Box mt="20px" />
      <BaseButton
        action={contactUsAction}
        extraCssClass={modules.ButtonSize}
        text="Contact Us 聯系我們"
      />
    </Paper>
  );
};

export default VisitUsCard;
