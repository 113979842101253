import { Box } from "@mui/material";
import React from "react";

import modules from "./styles/MenuAppFooter.module.css";

const MenuAppFooter: React.FC = () => {
  return (
    <div className={modules.FooterDiv}>
      <div className={modules.Links}>
        <a href="/">Home | 首頁</a>
        <a href="/messages">Messages | 信息</a>
        <a href="/mccf">MCCF | 福音團契</a>
        <a href="/olivet">Olivet Bible Fellowship</a>
        <a href="/family-gathering">Family Gathering | 家庭聚會</a>
        <a href="/resources">Resources | 資源</a>
      </div>
      <Box mt="30px" />
      <div className={modules.CopyRightDiv}>
        © 2023 Austin Christian Assembly 基督徒交通中心
      </div>
    </div>
  );
};

export default MenuAppFooter;
