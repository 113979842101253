import { Box } from "@mui/material";
import modules from "./styles/Subpage.module.css";

interface SubpageProps {
  titleText?: string;
  backgroundImageUrl?: string;
  children: any;
}

const Subpage: React.FC<SubpageProps> = ({
  titleText = "Page Title",
  backgroundImageUrl = "https://www.rentcafe.com/blog/wp-content/uploads/sites/62/2019/06/Austin_Texas_Downtown_Skyline_soft_light.jpg",
  children,
}) => {
  return (
    <div className={modules.Container}>
      <div
        className={modules.HeaderContainer}
        style={{ backgroundImage: `url("${backgroundImageUrl}")` }}
      />
      <div className={modules.Header}>{titleText}</div>
      {children}
    </div>
  );
};

export default Subpage;
