import { Paper } from "@mui/material";
import React, { memo, useState } from "react";
import modules from "./styles/BaseButton.module.css";

export interface BaseButtonProps {
  text: string;
  style?: {
    color?: string;
  };
  actionWhenHover?: (any: any) => void;
  actionWhenLeave?: (any: any) => void;
  action?: (any: any) => void;
  disabled?: boolean;
  extraCssClass?: string;
  labelCssClass?: string;
  link?: string;
  dark?: boolean;
}

const BaseButton: React.FC<BaseButtonProps> = ({
  text,
  style = {},
  action = () => {},
  extraCssClass = "",
  labelCssClass = "",
  disabled = false,
  link = "",
  dark = false,
}) => {
  const [hovered, setHovered] = useState(false);

  const ThemeColors = {
    palette: {
      white: "#ffffff",
      grey: "#808080",
      black: "#000000",
      dark: "#EB5757",
      light: "#ffffff",
    },
  };

  return (
    <Paper
      classes={{ root: `${modules.PaperContainer} ${extraCssClass}` }}
      elevation={3}
    >
      <button
        className={`Button-No-Standard-Style ${modules.ButtonBasicStyle} ${extraCssClass} filter drop-shadow-xl`}
        style={
          dark
            ? {
                backgroundColor: disabled
                  ? ThemeColors.palette.dark
                  : hovered
                  ? ThemeColors.palette.dark
                  : ThemeColors.palette.dark,
                border: "none",
              }
            : {
                border: `2px solid ${
                  disabled
                    ? ThemeColors.palette.grey
                    : hovered
                    ? ThemeColors.palette.grey
                    : ThemeColors.palette.grey
                }`,
                backgroundColor: hovered
                  ? ThemeColors.palette.light
                  : ThemeColors.palette.light,
              }
        }
        aria-describedby={text}
        onFocus={() => {
          setHovered(true);
        }}
        onBlur={() => {
          setHovered(false);
        }}
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        onClick={
          link
            ? () => {
                window.location.href = link;
              }
            : action
        }
        disabled={disabled}
      >
        <span
          className={`${modules.Label} ${labelCssClass}`}
          style={
            dark
              ? {
                  color: ThemeColors.palette.white,
                }
              : {
                  color: ThemeColors.palette.black,
                }
          }
        >
          {text}
        </span>
      </button>
    </Paper>
  );
};

export default memo(BaseButton);
