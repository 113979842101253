import { Box } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import Subpage from "../subpage/Subpage";
import MessageInfo from "./MessageInfo";
import sermons from "../../resources/sermons.json";
import modules from "./styles/Messages.module.css";

const Messages = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    return window.removeEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <Subpage
      titleText="Messages"
      backgroundImageUrl="https://storage.googleapis.com/acafamily/images/msg_cover.JPG"
    >
      <div className={modules.Container}>
        {/* TODO: implement search and filter feature before R1 */}
        {/* <div className={modules.SearchContainer}>
          <SearchBar label="Keyword" />
          <span className={modules.FilterContainer}>
            <span className={modules.LabelText}>Filters</span>
            <ChipMultipleSelect label="Speaker" />
            <ChipMultipleSelect label="Year" />
          </span>
        </div>
        <Box mt="36px" /> */}
        {width >= 768 && (
          <div className={modules.HeaderContainer}>
            <span className={modules.HeaderTitleSpan}>Message Title</span>
            <span className={modules.HeaderInfoSpan}>Date</span>
            <span className={modules.HeaderInfoSpan}>Speaker</span>
            <span className={modules.HeaderInfoSpan}>Location</span>
            <span className={modules.HeaderInfoSpan}>Recording</span>
          </div>
        )}
        {sermons.data.map((entry) => {
          return (
            <React.Fragment>
              <MessageInfo
                title={entry.title}
                date={entry.date}
                speaker={entry.speaker}
                location={entry.location}
                audioUrl={entry.audio_link}
                videoUrl={entry.video_link}
              />
              <Box mt="16px" />
            </React.Fragment>
          );
        })}
      </div>
    </Subpage>
  );
};

export default Messages;
