import Subpage from "../subpage/Subpage";

import modules from "./styles/Resources.module.css";

const Resources = () => {
  return (
    <Subpage
      titleText="Resources"
      backgroundImageUrl="https://storage.googleapis.com/acafamily/images/fm_cover.JPG"
    >
      <div className={modules.Container}>
        <a href="https://www.hcafamily.org/ui/msg.html?page=Conf:HCA%20Summer%20Conference">
          Summer Conference Messages
        </a>
        <a href="http://www.hcafamily.org">Houston Christian Assembly</a>
        <a href="http://www.westcoastchristianconference.com">
          West Coast Christian Conference
        </a>
        <a href="http://www.christianstudy.com/hymnary.html">
          圣徒诗歌 Hymnary
        </a>
        <a href="http://www.christiantapeministry.com">
          Christian Tape Ministry
        </a>
        <a href="https://storage.googleapis.com/acafamily/files/The_Prayer_Ministry_of_the_Church_English.pdf">
          The Prayer Ministry of the Church
        </a>

        <a href="https://storage.googleapis.com/acafamily/files/The_Prayer_Ministry_of_the_Church_Chinese.pdf">
          教会祷告的职事
        </a>
      </div>
    </Subpage>
  );
};

export default Resources;
