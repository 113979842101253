import { Paper } from "@mui/material";
import { ReactComponent as RegistrationIcon } from "../resources/assets/icons/registration.svg";
import { ReactComponent as YtbIcon } from "../resources/assets/icons/ytb.svg";

import modules from "./styles/UsefulLinks.module.css";

interface LinkItemProps {
  icon: any;
  text: string;
  url: string;
}

const LinkItem: React.FC<LinkItemProps> = ({ icon, text, url }) => {
  return (
    <a className={modules.LinkItemContainer} href={url}>
      <div className={modules.LinkItemIcon}>{icon}</div>
      {text}
    </a>
  );
};

const UsefulLinks = () => {
  return (
    <Paper classes={{ root: modules.Container }} elevation={3}>
      <div className={modules.TitleText}>USEFUL LINKS 常用鏈接</div>
      <LinkItem
        icon={<YtbIcon />}
        text={"YouTube Channel 油管頻道"}
        url={"https://www.youtube.com/channel/UCUdL9jv-HrzGS9cuqKIpbbA/streams"}
        key={2}
      />
      <LinkItem
        icon={<RegistrationIcon />}
        text={"Summer Conference 夏令會"}
        url={
          "https://www.hcafamily.org/ui/msg.html?page=Conf:HCA%20Summer%20Conference"
        }
        key={0}
      />
    </Paper>
  );
};

export default UsefulLinks;
