import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import Subpage from "../subpage/Subpage";
import lessons from "../../resources/ft2022.json";

import modules from "./styles/Resources.module.css";

const FT2022 = () => {
  return (
    <Subpage
      titleText="2022年基要真理課程資源"
      backgroundImageUrl="https://storage.googleapis.com/acafamily/images/mccf_cover_2.JPG"
    >
      <div className={modules.Container}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Speaker</TableCell>
                <TableCell align="left">Location</TableCell>
                <TableCell align="left">Handout</TableCell>
                <TableCell align="left">Slides / Readings</TableCell>
                <TableCell align="left">Audio</TableCell>
                <TableCell align="left">Video</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lessons.map((row) => (
                <TableRow
                  key={row.date}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.date}
                  </TableCell>
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="left">{row.speaker}</TableCell>
                  <TableCell align="left">{row.location}</TableCell>
                  <TableCell align="left">
                    {<a href={row.handout}>Link</a>}
                  </TableCell>
                  <TableCell align="left">
                    {<a href={row.slides}>Link</a>}
                  </TableCell>
                  <TableCell align="left">
                    {<a href={row.audio}>Link</a>}
                  </TableCell>
                  <TableCell align="left">
                    {<a href={row.video}>Link</a>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Subpage>
  );
};

export default FT2022;
