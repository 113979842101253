import { Box, Paper } from "@mui/material";

import modules from "./styles/AgendaCard.module.css";

interface AgendaCardProps {
  meetingFormat: string;
  bibleChapter: string;
  date: string;
}

const AgendaCardMCCF: React.FC<AgendaCardProps> = ({
  meetingFormat,
  bibleChapter,
  date,
}) => {
  return (
    <Paper classes={{ root: modules.Container }} elevation={3}>
      <div className={modules.TitleText}>聚会内容</div>
      <Box mt="16px" />
      <div className={modules.BodyText}>
        <p style={{ fontWeight: "bold" }}>聚会形式:</p>
        <p>{meetingFormat}</p>
      </div>
      <div className={modules.BodyText}>
        <p style={{ fontWeight: "bold" }}>查考圣经章节:</p>
        <p>{bibleChapter}</p>
      </div>
      <div className={modules.BodyText}>
        <p style={{ fontWeight: "bold" }}>时间（美国中部时区）:</p>
        <p>{date}</p>
        <ul>
          <li>晚餐: 6:30 - 7:20pm</li>
          <li>诗歌敬拜: 7:30 - 8:00pm</li>
          <li>查考圣经: 8:00 - 9:30pm</li>
        </ul>
      </div>
    </Paper>
  );
};

export default AgendaCardMCCF;
