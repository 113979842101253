import moment from "moment";
import BaseButton from "./BaseButton";
import { Box } from "@mui/system";
import { Paper } from "@mui/material";

import sermons from "../resources/sermons.json";

import modules from "./styles/WelcomeBadge.module.css";

const WelcomeBadge = () => {
  const latestLink =
    sermons.data[0].video_link ||
    "https://www.youtube.com/channel/UCUdL9jv-HrzGS9cuqKIpbbA/streams";

  return (
    <Paper classes={{ root: modules.Container }} elevation={5}>
      <div className={modules.TitleText}>Welcome to ACA!</div>
      <div className={modules.TitleText}>基督徒交通中心歡迎您！</div>
      <Box mt="8px" />
      <span className={modules.ButtonContainer}>
        <BaseButton
          text={
            moment().day() === 0
              ? "Message Livestream 信息直播"
              : "Latest Message 上週信息"
          }
          extraCssClass={modules.Button}
          dark
          link={
            moment().day() === 0
              ? "https://www.youtube.com/channel/UCUdL9jv-HrzGS9cuqKIpbbA/featured"
              : latestLink
          }
        />
        <BaseButton
          text={"YouTube Channel 油管頻道"}
          extraCssClass={modules.Button}
          link={
            "https://www.youtube.com/channel/UCUdL9jv-HrzGS9cuqKIpbbA/streams"
          }
        />
      </span>
    </Paper>
  );
};

export default WelcomeBadge;
