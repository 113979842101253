import { Box } from "@mui/material";
import React from "react";
import Subpage from "../subpage/Subpage";

import modules from "./styles/FamilyGathering.module.css";

interface GatheringDetailProps {
  name: string;
  content: string;
  time: string;
  host: string;
  location: string;
}

const GatheringDetail: React.FC<GatheringDetailProps> = ({
  name,
  content,
  time,
  host,
  location,
}) => {
  return (
    <React.Fragment>
      <div style={{ fontWeight: "bold" }}>{name}</div>
      <ul>
        <li>{`Content: ${content}`}</li>
        <li>{`Time: ${time}`}</li>
        <li>{`Hosted by: ${host}`}</li>
        <li>{`Location: ${location}`}</li>
      </ul>
      <Box mt="18px" />
    </React.Fragment>
  );
};

const FamilyGathering = () => {
  return (
    <Subpage
      titleText="Family Gathering"
      backgroundImageUrl="https://storage.googleapis.com/acafamily/images/cover.JPG"
    >
      <div className={modules.Container}>
        <div>Page updated on: 9/1/2022</div>
        <Box mt="18px" />
        <div>
          Family gatherings are meetings hosted by brothers and sisters outside
          of normal meeting schedule as per the needs of the saints. You may
          find below a list of family gatherings that are happening this month
          below.
        </div>
        <Box mt="36px" />
        <GatheringDetail
          name="Southside Family Gathering"
          content="Live out the abundance life book by Bro Godwin Sun"
          time="Fourth Saturday of each month at 4pm"
          host="Currently the gathering is held via Zoom"
          location="Please use contact form to request Zoom meeting information"
        />
      </div>
    </Subpage>
  );
};

export default FamilyGathering;
