import { Paper } from "@mui/material";
import { Box } from "@mui/system";

import modules from "./styles/MeetingCard.module.css";

interface MeetingCardProps {
  mtgEnName: string;
  mtgCnName?: string;
  time: string;
  location: string;
}

const MeetingCard: React.FC<MeetingCardProps> = ({
  mtgEnName,
  mtgCnName = "",
  time,
  location,
}) => {
  return (
    <Paper
      classes={{
        root: modules.Container,
      }}
      elevation={3}
    >
      <div className={modules.MeetingName}>{mtgEnName}</div>
      <Box mt="8px" />
      <div className={modules.MeetingName}>{mtgCnName}</div>
      <Box mt="32px" />
      <div className={modules.SectionHeader}>Time</div>
      <Box mt="16px" />
      <div className={modules.Text}>{time}</div>
      <Box mt="16px" />
      <div className={modules.SectionHeader}>Location</div>
      <Box mt="16px" />
      <div className={modules.Text}>{location}</div>
    </Paper>
  );
};

export default MeetingCard;
