import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import { Drawer, IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import modules from "./styles/MenuAppBar.module.css";

function ElevationScroll(props: any) {
  const { children } = props;

  return React.cloneElement(children, {
    elevation: 4,
  });
}

const MenuAppBar = (props: any) => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 768);
    });

    return window.removeEventListener("resize", () => {
      setIsMobile(window.innerWidth < 768);
    });
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickCampus = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseCampus = () => {
    setAnchorEl(null);
  };

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const toggleDrawer =
    (status: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenDrawer(status);
    };

  const menuItems = (
    <React.Fragment>
      <a className={modules.Link} href="/" onClick={toggleDrawer(false)}>
        <span>Home</span>
        <span style={{ fontSize: "18px" }}>首頁</span>
      </a>
      <a
        className={modules.Link}
        href="/messages"
        onClick={toggleDrawer(false)}
      >
        <span>Messages</span>
        <span style={{ fontSize: "18px" }}>信息</span>
      </a>
      <span className={modules.Link} onClick={handleClickCampus}>
        <span>Campus</span>
        <span style={{ fontSize: "18px" }}>校園</span>
      </span>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseCampus}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <a
            className={modules.Link}
            style={{ fontSize: "16px" }}
            href="/mccf"
            onClick={() => {
              //TODO: optimize code to be in one function
              handleCloseCampus();
              toggleDrawer(false);
            }}
          >
            福音團契
          </a>
        </MenuItem>
        <MenuItem>
          <a
            className={modules.Link}
            style={{ fontSize: "16px" }}
            href="/olivet"
            onClick={() => {
              //TODO: optimize code to be in one function
              handleCloseCampus();
              toggleDrawer(false);
            }}
          >
            Olivet Bible Fellowship
          </a>
        </MenuItem>
      </Menu>
      <a
        className={modules.Link}
        href="/family-gathering"
        onClick={toggleDrawer(false)}
      >
        <span>Family Gathering</span>
        <span style={{ fontSize: "18px" }}>家庭聚會</span>
      </a>
      <a
        className={modules.Link}
        href="/resources"
        onClick={toggleDrawer(false)}
      >
        <span>Resources</span>
        <span style={{ fontSize: "18px" }}>資源</span>
      </a>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar classes={{ root: modules.NavBar }}>
          <Toolbar classes={{ root: modules.NavBarContent }}>
            {!isMobile && <div className={modules.Links}>{menuItems}</div>}
            {isMobile && (
              <React.Fragment>
                <a className={modules.Logo} href="/">
                  <span>Austin Christian Assembly</span>
                  <span>基督徒交通中心</span>
                </a>
                <IconButton
                  size="large"
                  color="inherit"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon style={{ color: "black" }} />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={openDrawer}
                  onClose={toggleDrawer(false)}
                >
                  <div className={modules.MenuDrawer}>{menuItems}</div>
                </Drawer>
              </React.Fragment>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </React.Fragment>
  );
};

export default MenuAppBar;
